const url = 'https://psicologainfantil.mx'
const siteURL = process.env.NODE_ENV == 'production' ? url : 'http://localhost:3000/'

const Config = {
  site: {
    name: 'Psicóloga Infantil',
    URL: siteURL,
    title: 'Clínica de Diagnóstico y Tratamiento Infantil',
    logo: '/images/logo.png',
    preview: '/preview.png',
    description:
      'Psicóloga Infantil, problemas emocionales, de conducta, bullying. Porque la infancia es la etapa mas importante, establecen las bases para el resto de su vida.',
    creator: '@iver14',
  },
  contact: {
    endpoint: 'https://formspree.io/',
    email: 'YWRtaW5AcHNpY29sb2dhaW5mYW50aWwubXg=',
    cc: 'bWFyaWEudmFsbGVAdGVyYXBpYXZpc3VhbC5teA==',
    subject: 'W3BzaS5teF0=',
    phonelink: 'tel:+5555350814',
    phonemask: '+52-5555-35-0814',
    formcode: 'mbjqdzzk',
    address: 'Rio Guadalquivir 32, Col. Cuauhtémoc, Del. Cuauhtémoc. CP. 06500',
  },
  whatsapp: {
    phone: '525512961576',
    phoneMask: '(55) 1296 1576',
    message: '[PSI] ¿En qué podemos apoyarle?',
    scheme: 'https://',
  },
  social: {
    twitter: 'https://twitter.com/@psi_delvalle_mx',
    facebook: 'https://www.facebook.com/www.psicologainfantil.mx',
    linkedin: 'https://mx.linkedin.com/in/terapiavisual',
  },
  google: {
    trackAW: 'AW-977831659',
    trackGTM: 'GTM-T9V9SFC',
    trackGA: 'G-1VXTS07K8Z',
    conversionID: 'yBV7COi42vECEOuNotID',
    conversionPhone: 'enklCI3smV4Q642i0gM',
    conversionMail: 'Ntw1CMjK2eUZEOuNotID',
  },
}

export default Config
