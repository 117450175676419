'use client'
import React from 'react'
import { useForm } from 'react-hook-form'
import { sendGAEvent, sendGTMEvent } from '@next/third-parties/google'

import Config from '@/data/site'
import ErrorMessage from '@/components/contact/ErrorMessage'
import builtWALink from './code'

export type FormData = {
  whatsmessage: string
}

const InputMessage = () => {
  const { trackAW, trackGTM, conversionID } = Config.google
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const onSubmit = async (data: FormData) => {
    const { whatsmessage } = data
    console.log('from whats')
    const link = await builtWALink(whatsmessage)

    Promise.all([
      sendGAEvent({
        event: 'conversion',
        value: { send_to: `${trackAW}/${conversionID}` },
      }),
      sendGTMEvent({
        event: 'conversion',
        value: { send_to: `${trackGTM}/${conversionID}` },
      }),
    ])
      .then(() => {
        window.gtag('event', 'conversion', {
          send_to: `${trackGTM}/${conversionID}`,
        })
        // Redirigir a un sitio externo
        window.open(link, '_blank', 'noopener,noreferrer')
      })
      .catch((error) => {
        console.error('Error registrando eventos en GTM y GA:', error)
        // Opción para manejar el error o redirigir de todos modos
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="whats">
      {errors.whatsmessage && (
        <ErrorMessage
          message={errors.whatsmessage.message}
          className="relative text-end font-bold text-lg px-2 top-24"
        />
      )}
      <div className="absolute bottom-0 grid grid-cols-4 w-full bg-white h-1/4 rounded-b-lg ">
        <div className="col-span-3 ">
          <textarea
            id="whatsmessage"
            rows={4}
            placeholder=""
            className="block px-2.5  pt-4 w-full h-4/5 text-sm text-gray-900 bg-transparent rounded-lg border-0 border-primary-500 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary-500 focus:outline-none focus:ring-0 focus:border-primary-500 focus:border-2 peer"
            {...register('whatsmessage', { required: 'Debe ingresar un mensaje' })}
          ></textarea>
          <label
            htmlFor="whatsmessage"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-100 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-primary-600 peer-focus:dark:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Ingrese aquí su mensaje
          </label>
        </div>
        <div className="flex justify-evenly">
          <button
            className=" px-3 py-2 text-sm my-8 font-medium text-center text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            type="submit"
          >
            Enviar
          </button>
        </div>
      </div>
    </form>
  )
}

export default InputMessage
